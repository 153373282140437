import { Outlet } from 'main/ts/view/common/Outlet'

import { FlexBoxCenteredLayout } from '../../common/layout/flex-box'

export const SourcePage = () => {
  return (
    <FlexBoxCenteredLayout>
      <Outlet />
    </FlexBoxCenteredLayout>
  )
}
