import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Icons } from '@pijma/crypto'

import { EPaymentMethod } from '@api/types'
import { useToken } from '@hooks'
import { Dispatch, RootState } from '@stores'
import { EPaymentSource } from '@stores/types/EPaymentSource'
import styled from 'styled-components'

import { DarkBgClass } from '../../../../generated/quicktype/Tokens'
import { EPaymentProvider } from '../../../stores/types/EPaymentProvider'
import { H4 } from '../../common/header/Headings'
import { Island } from '../../common/island'
import { FlexBoxCenteredLayout } from '../../common/layout/flex-box'
import {
  BodyText500,
  BodyText600,
  CaptionText500,
} from '../../common/text/BodyText'

const Container = styled.div<DarkBgClass>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(bgClass) => bgClass.primary.default.value};
`

const SourceTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px 0;
  margin-top: 24px;
  margin-bottom: 16px;
`

const SourceBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px 0;
  margin-bottom: 8px;
`

const SourceWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 16px;
  margin-right: 16px;
`

const SourceNameDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  width: fit-content;
`

const SourceName = styled.div`
  align-self: stretch;
  margin-top: 12px;
  font-smooth: never;
`

const SourceDescription = styled.div<DarkBgClass>`
  align-self: stretch;
  margin-bottom: 16px;
  color: ${(bgClass) => bgClass.secondary.default.value};
`

const SourceValue = styled.div`
  text-align: right;
  margin-left: 20px;
  margin-top: 12px;
`

const BackContainer = styled.div`
  margin: 24px 0 0 0;
  color: #217aac;
`

interface StateProps {
  paymentMethods: EPaymentMethod[] | undefined
}

type Props = StateProps & DispatchProps

interface DispatchProps {
  setPaymentSource: (paymentSource: EPaymentSource) => void
}

export const SourceSelectPage = (props: Props) => {
  const redirectTo = useNavigate()

  const goToInputPayerInfo = (source: EPaymentSource) => {
    props.setPaymentSource(source)
    redirectTo(`../source/${source}`)
  }

  const tokens = useToken()
  return (
    <FlexBoxCenteredLayout>
      <Container {...tokens.color.font.light_bg}>
        <SourceTitle>
          <H4>Способ оплаты</H4>
        </SourceTitle>
        {props.paymentMethods?.includes(EPaymentMethod.BANK_CARD) && (
          <SourceBlock onClick={() => goToInputPayerInfo(EPaymentSource.CARD)}>
            <Island size="M" bgColor="primary">
              <SourceWrapper>
                <SourceNameDescription>
                  <SourceName>
                    <BodyText600>Перевод с карты</BodyText600>
                  </SourceName>
                  <SourceDescription {...tokens.color.font.light_bg}>
                    <CaptionText500>Продавцу по номеру карты</CaptionText500>
                  </SourceDescription>
                </SourceNameDescription>
                <SourceValue>
                  <Icons.IconCard />
                </SourceValue>
              </SourceWrapper>
            </Island>
          </SourceBlock>
        )}
        {props.paymentMethods?.includes(EPaymentMethod.SBP) && (
          <SourceBlock onClick={() => goToInputPayerInfo(EPaymentSource.SBP)}>
            <Island size="M" bgColor="primary">
              <SourceWrapper>
                <SourceNameDescription>
                  <SourceName>
                    <BodyText600>Перевод через СБП</BodyText600>
                  </SourceName>
                  <SourceDescription {...tokens.color.font.light_bg}>
                    <CaptionText500>По номеру телефона</CaptionText500>
                  </SourceDescription>
                </SourceNameDescription>
                <SourceValue>
                  <BodyText600>
                    <Icons.IconSbp />
                  </BodyText600>
                </SourceValue>
              </SourceWrapper>
            </Island>
          </SourceBlock>
        )}
        <BackContainer onClick={() => redirectTo('../provider-select')}>
          <BodyText500>Назад</BodyText500>
        </BackContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  const paymentMethods = (() => {
    if (state.payForm?.paymentProvider === EPaymentProvider.RT) {
      return state.paymentProviders?.RT?.providerPaymentMethods
    }
    return state.paymentProviders?.M3?.providerPaymentMethods
  })()

  return {
    paymentMethods,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPaymentSource: (paymentSource: EPaymentSource) =>
      dispatch.payForm.set({
        paymentSource,
      }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SourceSelectPage)
