import { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { topupApplicationClient } from '@api'
import { EPaymentMethod, fromResponse } from '@api/types'
import { useToken } from '@hooks'
import { RootState } from '@stores'
import {
  toPaymentMethod,
  TPayFormState,
  TPaymentProvidersState,
} from '@stores/types/TPayFormState'
import styled from 'styled-components'

import { Color } from '../../../generated/quicktype/Tokens'
import {
  payFormService,
  personalDataService,
  providerService,
} from '../../service/payform'
import { H3Weight600 } from '../common/header/Headings'
import { Outlet } from '../common/Outlet'

const Header = styled.div<Color>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(color) => color.bg.page.value};
`

const IslandBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px 0;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 600;
`

interface StateProps {
  payForm: TPayFormState
  paymentProviders: TPaymentProvidersState
}

type Props = StateProps

// application reducer
interface TApplicationState {
  applicationId: string
}

enum ApplicationReducerActions {
  GET,
}

interface ApplicationReducerAction {
  action: ApplicationReducerActions
  applicationId: string
}

const applicationReducer = (
  state: TApplicationState,
  action: ApplicationReducerAction,
) => {
  if (action.action === ApplicationReducerActions.GET) {
    if (!action.applicationId) return state
    if (state.applicationId === action.applicationId) return state
    topupApplicationClient.application(action.applicationId).then((res) => {
      payFormService.initPayForm(fromResponse(res))
    })
    return { applicationId: action.applicationId }
  }
  return { applicationId: '' }
}

interface PersonalDataReducerAction {
  userId: string
  paymentMethod: EPaymentMethod
}

interface PersonalDataState {
  fetched: boolean
}

const personalDataReducer = (
  state: PersonalDataState,
  action: PersonalDataReducerAction,
) => {
  if (!state.fetched) {
    topupApplicationClient
      .personalData(action.userId, action.paymentMethod)
      .then((res) => personalDataService.init(res))
    return { fetched: true }
  }
  return state
}

interface ProviderState {
  fetched: boolean
}

const providerReducer = (state: ProviderState) => {
  if (!state.fetched) {
    topupApplicationClient
      .paymentProviders()
      .then((res) => providerService.initPaymentMethods(res))
    return { fetched: true }
  }
  return state
}

export const WrapperPage = (props: Props) => {
  const initialApplication: TApplicationState = { applicationId: '' }
  const initialPersonalData = { fetched: false }
  const initialPaymentProviders = { fetched: false }

  const applicationId = useParams().applicationId
  const [, applicationDispatch] = useReducer(
    applicationReducer,
    initialApplication,
  )
  const [, personalDataDispatch] = useReducer(
    personalDataReducer,
    initialPersonalData,
  )

  const [, providerDispatch] = useReducer(
    providerReducer,
    initialPaymentProviders,
  )

  useEffect(() => {
    if (!applicationId || applicationId === 'undefined') {
      return
    } else {
      applicationDispatch({
        action: ApplicationReducerActions.GET,
        applicationId: applicationId,
      })
    }
  }, [applicationId])

  useEffect(() => {
    if (props.payForm.application?.userId && props.payForm.paymentSource) {
      personalDataDispatch({
        userId: props.payForm.application?.userId,
        paymentMethod: toPaymentMethod(props.payForm.paymentSource),
      })
    }
  }, [props.payForm.application?.userId, props.payForm.paymentSource])

  useEffect(() => {
    providerDispatch()
  }, [])

  return (
    <>
      <Header {...useToken().color}>
        <IslandBlock>
          <H3Weight600>Покупка USDT TRC20</H3Weight600>
        </IslandBlock>
      </Header>
      <Outlet />
    </>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
  paymentProviders: state.paymentProviders,
})

export default connect(mapStateToProps)(WrapperPage)
