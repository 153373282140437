import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { EPaymentSource } from '@stores/types/EPaymentSource'
import {
  CancelPage,
  PendingPage,
  ResultPage,
  SourcePage,
} from 'main/ts/view/pages'

import { IconError } from '../view/common/icons/IconError'
import { IconWarn } from '../view/common/icons/IconWarn'
import { SuccessPage } from '../view/pages'
import ApplicationCreatorPage from '../view/pages/ApplicationCreatorPage'
import { ClosePage } from '../view/pages/close-page/ClosePage'
import PaymentPage from '../view/pages/payment-page/PaymentPage'
import ProviderSelectPage from '../view/pages/provider-select-page/ProviderSelectPage'
import SourceCardPage from '../view/pages/source-page/source-card-page/SourceCardPage'
import SourceSbpPage from '../view/pages/source-page/source-sbp-page/SourceSbpPage'
import SourceSelectPage from '../view/pages/source-select-page/SourceSelectPage'
import SumPage from '../view/pages/sum-page/SumPage'
import WrapperPage from '../view/pages/WrapperPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <ApplicationCreatorPage />,
  },
  {
    path: '/application/:applicationId',
    element: <WrapperPage />,
    children: [
      {
        path: 'error',
        element: (
          <ResultPage
            tip={'Обновите страницу\nили зайдите позже'}
            btnText="Обновить"
            icon={<IconWarn />}
          />
        ),
      },
      {
        path: 'payment',
        element: <PaymentPage />,
      },
      {
        path: 'failure',
        element: (
          <ResultPage
            title="Платеж не прошёл"
            tip="Попробуйте ещё раз"
            btnText="Закрыть"
            icon={<IconError />}
          />
        ),
      },
      {
        path: 'source-select',
        element: <SourceSelectPage />,
      },
      {
        path: 'sum',
        element: <SumPage />,
      },
      {
        path: `provider-select`,
        element: <ProviderSelectPage />,
      },
      {
        path: 'source',
        element: <SourcePage />,
        children: [
          {
            path: EPaymentSource.CARD,
            element: <SourceCardPage />,
          },
          {
            path: EPaymentSource.SBP,
            element: <SourceSbpPage />,
          },
        ],
      },
      {
        path: 'pending',
        element: <PendingPage />,
      },
      {
        path: '*',
        element: (
          <ResultPage
            title={'То, что вы ищите\u00A0—\nпотерялось'}
            tip="Или никогда не существовало"
            btnText="Закрыть"
            icon={<IconWarn />}
          />
        ),
      },
    ],
  },
  {
    path: 'application/:applicationId/success',
    element: <SuccessPage />,
  },
  {
    path: 'application/:applicationId/cancel',
    element: <CancelPage />,
  },
  {
    path: 'application/close',
    element: <ClosePage />,
  },
])

export const Router = () => {
  return <RouterProvider router={router} />
}
