import { httpClient, IHttpClient } from '@api'
import {
  EPaymentMethod,
  TApplicationResponse,
  TTopupResponse,
} from '@api/types'

import { EPaymentProvider } from '../../stores/types/EPaymentProvider'
import { TPaymentProviderResponse } from '../types/TPaymentProviderResponse'
import { TPersonalData } from '../types/TPersonalData'

interface ITopupApplicationClient {
  topup(
    applicationId: string,
    paymentMethod: EPaymentMethod,
    amount: string,
    currencyISOAlpha3: string,
    sender: string,
    provider: EPaymentProvider,
    phone?: string,
    last4pan?: string,
  ): Promise<TTopupResponse>
  personalData(
    userId: string,
    paymentMethod: EPaymentMethod,
  ): Promise<TPersonalData>
  application(applicationId: string): Promise<TApplicationResponse>
  createApplication(
    userId: string,
    email: string,
  ): Promise<TApplicationResponse>
  paymentProviders(): Promise<TPaymentProviderResponse>
}

const topupApplicationClientFactory = (
  client: IHttpClient,
): ITopupApplicationClient => {
  return {
    topup(
      applicationId: string,
      paymentMethod: EPaymentMethod,
      amount: string,
      currencyISOAlpha3: string,
      sender: string,
      provider: EPaymentProvider,
      phone?: string,
      last4pan?: string,
    ): Promise<TTopupResponse> {
      return client.post(
        `/client/v1/topup-applications/${applicationId}/topup?provider=${provider}`,
        {
          paymentMethod: paymentMethod,
          sender: sender,
          phone: phone,
          last4pan: last4pan,
          topupSum: {
            amount,
            currencyISOAlpha3,
          },
        },
      )
    },
    personalData(
      userId: string,
      paymentMethod: EPaymentMethod,
    ): Promise<TPersonalData> {
      return client.get(
        `/client/v1/topup-applications/personal-data?userId=${userId}&paymentMethod=${paymentMethod}`,
      )
    },
    application(applicationId: string): Promise<TApplicationResponse> {
      return client.get(`/client/v1/topup-applications/${applicationId}`)
    },
    createApplication(
      userId: string,
      email: string,
    ): Promise<TApplicationResponse> {
      return client.put(`/client/v1/topup-applications`, {
        userId,
        email,
      })
    },
    paymentProviders(): Promise<TPaymentProviderResponse> {
      return client.get(`/client/v1/providers`)
    },
  }
}

export const topupApplicationClient: ITopupApplicationClient =
  topupApplicationClientFactory(httpClient)
