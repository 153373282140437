import { FC } from 'react'

import { IconWarning } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'

export const IconWarn: FC = () => (
  <IconWarning
    color={useToken().color.common.primary.waiting.value}
    height="56px"
    width="56px"
  />
)
