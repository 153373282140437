import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { EApplicationStatus } from '@api/types'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/TPayFormState'

import { DWalletTransfer } from '../dealer-wallet-transfer/DWalletTransfer'

interface StateProps {
  payForm: TPayFormState
}

const PaymentPage = (props: StateProps) => {
  const location = useLocation()
  const redirectTo = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (!props.payForm.application?.status) return
    switch (props.payForm.application?.status) {
      case EApplicationStatus.PAYING: {
        window.location.href = props.payForm.application.paymentUrl
        break
      }
      case EApplicationStatus.FAIL:
      case EApplicationStatus.REJECT:
      case EApplicationStatus.PAID:
        redirectTo('../../close')
        break
      default: {
        redirectTo('../provider-select')
      }
    }
  }, [
    location.pathname,
    location.search,
    params.applicationId,
    redirectTo,
    props.payForm.application?.status,
    props.payForm.application?.paymentUrl,
  ])

  return <DWalletTransfer />
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(PaymentPage)
