import { TApplication } from '@api/types'
import { store } from '@stores'

import { TPaymentProviderResponse } from '../../api/types/TPaymentProviderResponse'
import { TPersonalData } from '../../api/types/TPersonalData'

export const payFormService = {
  initPayForm(application: TApplication) {
    store.dispatch.payForm.init({ application })
  },
}

export const personalDataService = {
  init(data: TPersonalData) {
    store.dispatch.payForm.updatePersonalData(data)
  },
}

export const providerService = {
  initPaymentMethods(paymentProviders: TPaymentProviderResponse) {
    store.dispatch.paymentProviders.init(paymentProviders)
  },
}
