import { createRoot } from 'react-dom/client'

import { App } from './main/ts/App'

const rootElement = document.querySelector('#root')
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (rootElement && !(window as any).__DISABLED__) {
  const root = createRoot(rootElement)
  root.render(<App />)
}
