import { IconLike } from '@pijma/icon-gallery/crypto'

import styled from 'styled-components'

import { H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const TitleWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;
  white-space: pre-wrap;
`

const DescriptionWrapper = styled.div`
  white-space: pre-wrap;
`

export const SuccessPage = () => {
  return (
    <FlexBoxCenteredLayout>
      <Container>
        <IconLike width="61px" height="61px" />

        <TitleWrapper>
          <H4>Оплачено</H4>
        </TitleWrapper>

        <DescriptionWrapper>
          <CaptionText500>
            {'Монеты зачислятся на баланс\nкошелька в течение 10 минут'}
          </CaptionText500>
        </DescriptionWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
