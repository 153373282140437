import { IconRain } from '@pijma/icon-gallery/crypto'

import styled from 'styled-components'

import { H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
`

export const CancelPage = () => {
  return (
    <FlexBoxCenteredLayout>
      <Container>
        <IconRain width="61px" height="61px" />

        <TitleWrapper>
          <H4>Сделка отменена</H4>
        </TitleWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
