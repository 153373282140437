export const phoneRegexp = new RegExp(
  '\\+\\d\\s\\(\\d{3}\\)\\s\\d{3}-\\d{2}-\\d{2}',
)

export const nameSurnameRuRegexp = new RegExp('[А-яёЁ-]{2,}\\s[А-яёЁ-]{2,}')

export const ruNameRegexp = /[\sа-яё-]/gi
export const notRuNameRegexp = /[^\sа-яё-]/gi

export const charIsRusLetterWithSpaces = (char?: string) => {
  return ruNameRegexp.test(char || '')
}

export const replaceNotRusSymbols = (val: string) => {
  return val.replace(notRuNameRegexp, '')
}

export const localeNumber = (formattedAmount: string | undefined) => {
  if (formattedAmount == undefined) return ''
  return Number(formattedAmount).toLocaleString('ru-RU', {
    maximumFractionDigits: 2,
  })
}
