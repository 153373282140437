import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Input } from '@pijma/crypto'

import { Dispatch, RootState } from '@stores'
import { TPayFormState } from '@stores/types/TPayFormState'
import styled from 'styled-components'

import { H4 } from '../../../common/header/Headings'
import { BodyText500, CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 20px 0;
`

const BackContainer = styled.div`
  margin: 24px 0 0 0;
  color: #217aac;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 20px 0;
`

const SourceTitle = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  width: 100%;
  padding: 0 24px 0;
  margin: 24px 0 0;
  gap: 8px;
`

const HeaderTip = styled.div`
  color: #868686;
`
interface StateProps {
  payForm: TPayFormState
}

interface DispatchProps {
  setSender: (sender: string) => void
  setLast4Pan: (last4pan: string) => void
  doPaymentBlocking: () => void
  resetPaymentBlocking: () => void
}

type Props = StateProps & DispatchProps

const SourceCardPage = (props: Props) => {
  const redirectTo = useNavigate()

  return (
    <Container>
      <SourceTitle>
        <H4>С какой карты вы будете делать перевод?</H4>
        <HeaderTip>
          <CaptionText500 color={'#868686'}>
            {'Вводите реальные данные.\nТак мы быстрее проведём сделку'}
          </CaptionText500>
        </HeaderTip>
      </SourceTitle>
      <InputContainer>
        <Input
          label={`Последние 4 цифры карты`}
          value={props.payForm.last4pan}
          width={'100%'}
          disabled={props.payForm.paymentBlocking}
          type={'text'}
          placeholder={'0000'}
          onChange={(e) => props.setLast4Pan(e.target.value)}
          allowClear={true}
        />
      </InputContainer>
      <InputContainer>
        <Input
          label={`Кому выдана`}
          value={props.payForm.sender}
          width={'100%'}
          disabled={props.payForm.paymentBlocking}
          type={'text'}
          description={'На русском языке'}
          placeholder={'Например, Антон Чехов'}
          onChange={(e) => props.setSender(e.target.value)}
          allowClear={true}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          width={'100%'}
          size={'m'}
          onPress={() => redirectTo(`../../sum`)}
        >
          К вводу суммы
        </Button>
      </ButtonContainer>
      <BackContainer onClick={() => redirectTo(`../../source-select`)}>
        <BodyText500>Назад</BodyText500>
      </BackContainer>
    </Container>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setLast4Pan: (last4pan: string) =>
      dispatch.payForm.set({
        last4pan,
      }),
    setSender: (sender: string) =>
      dispatch.payForm.set({
        sender,
      }),
    doPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: true,
      }),
    resetPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: false,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceCardPage)
