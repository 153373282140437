import { TApplication } from '@api/types'
import { createModel } from '@rematch/core'
import { RootModel } from '@stores'
import { TPayFormState } from '@stores/types/TPayFormState'

import { TPersonalData } from '../../api/types/TPersonalData'

export const payForm = createModel<RootModel>()({
  state: {
    amount: '0',
    paymentBlocking: false,
  } as TPayFormState,
  reducers: {
    set(state, payload: TPayFormState) {
      return { ...state, ...payload }
    },
  },
  effects: (dispatch) => ({
    init(payload: { application: TApplication }) {
      dispatch.payForm.set({
        ...payload,
        minLimit: payload.application.minLimit,
        maxLimit: payload.application.maxLimit,
      } as TPayFormState)
    },
    updatePersonalData(payload: TPersonalData) {
      dispatch.payForm.set({
        sender: payload.sender_name,
        phone: payload.sbp_phone?.slice(1),
        last4pan: payload.last4pan,
      })
    },
  }),
})
