import { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { topupApplicationClient } from '@api'
import { fromResponse } from '@api/types'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/TPayFormState'

import { payFormService } from '../../service/payform'

interface StateProps {
  payForm: TPayFormState
}

interface PutApplicationRState {
  requested: boolean
}

export const WrapperPage = (props: StateProps) => {
  const redirectTo = useNavigate()
  const [searchParams] = useSearchParams()

  const userId = searchParams.get('userId')
  const email = searchParams.get('email')

  const putApplicationReducer = (state: PutApplicationRState) => {
    if (!userId || !email) {
      return { requested: false }
    }
    if (state.requested) {
      return state
    }
    topupApplicationClient.createApplication(userId, email).then((res) => {
      payFormService.initPayForm(fromResponse(res))
    })
    return { requested: true }
  }
  const [, putApplicationDispatch] = useReducer(putApplicationReducer, {
    requested: false,
  })

  useEffect(() => {
    putApplicationDispatch()
  }, [])

  useEffect(() => {
    if (!props.payForm.application?.applicationUuid) {
      return
    }
    redirectTo(
      `/application/${props.payForm.application?.applicationUuid}/payment`,
    )
  }, [props.payForm.application?.applicationUuid, redirectTo])
  return null
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(WrapperPage)
