import { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@pijma/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { DarkBgClass } from '../../../../generated/quicktype/Tokens'
import { H4 } from '../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../common/layout/flex-box'
import { BodyText500 } from '../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 4px;
  white-space: pre-wrap;
`

const ButtonWrapper = styled.div`
  margin: 24px 0 16px 0;
`

const TipWrapper = styled.div<DarkBgClass>`
  color: ${(bgClass) => bgClass.secondary.default.value};
  white-space: pre-wrap;
`

interface IResultPage {
  title?: string
  tip?: string
  btnText: string
  btnAction?: () => void
  icon?: ReactElement
}

export const ResultPage: FC<IResultPage> = ({ title, tip, btnText, icon }) => {
  const tokens = useToken()
  const redirectTo = useNavigate()
  const closeWindow = () => {
    redirectTo('../../application/close')
  }

  return (
    <FlexBoxCenteredLayout>
      <Container>
        {icon}
        <TitleWrapper>
          <H4>{title ?? 'Что-то пошло не так'}</H4>
        </TitleWrapper>

        {tip ? (
          <TipWrapper {...tokens.color.font.light_bg}>
            <BodyText500>{tip}</BodyText500>
          </TipWrapper>
        ) : null}

        <ButtonWrapper>
          <Button type="primary" onPress={closeWindow}>
            {btnText}
          </Button>
        </ButtonWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
