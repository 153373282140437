import { useNavigate, useParams } from 'react-router-dom'

import { IconClock } from '@pijma/icon-gallery/crypto'

import { topupApplicationClient } from '@api'
import { EApplicationStatus, fromResponse } from '@api/types'
import { useToken } from '@hooks'
import { useInterval } from '@utils'

import { ResultPage } from '../result-page/ResultPage'

export const PendingPage = () => {
  const redirectTo = useNavigate()
  const pathParams = useParams()
  const tokens = useToken()
  const iconClock = (
    <IconClock
      color={tokens.color.common.primary.waiting.value}
      height="56px"
      width="56px"
    />
  )

  const applicationId = pathParams.applicationId as string
  useInterval(
    () =>
      topupApplicationClient.application(applicationId).then((res) => {
        const application = fromResponse(res)
        switch (application.status) {
          case EApplicationStatus.READY_FOR_PAY:
            redirectTo('../provider-select')
            break
          case EApplicationStatus.PAYING: // do nothing
            break
          case EApplicationStatus.PAID:
            redirectTo('../success')
            break
          case EApplicationStatus.FAIL:
          case EApplicationStatus.REJECT:
            redirectTo('../failure')
        }
      }),
    2000,
  )

  return (
    <ResultPage
      title="Платеж в&nbsp;обработке"
      tip={'Деньги поступят на\u00A0баланс \nв\u00A0течение минуты'}
      btnText="Закрыть"
      icon={iconClock}
    />
  )
}
